<template>
  <div :class="['player-view-wrapper', templateAreaClass]">
    <PlayerField
      v-for="player in players"
      class="tile"
      :id="getPlayerFieldId(player)"
      :player="player"
      :action-chain="actionChain"
      @on-game-action="onGameAction"
    />
    <div id="menu">
      <button>reload</button>
      <button>reload</button>
      <button>reload</button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { GameAction, Player } from "./lifelog.model";
import PlayerField from "@/components/PlayerField.vue";

const props = defineProps<{
  altView: Boolean;
  players: Array<Player>;
  actionChain: Array<GameAction>;
}>();
const emit = defineEmits(["onGameAction"]);
function onGameAction(action: GameAction) {
  emit("onGameAction", action);
}

function getPlayerFieldId(player: Player) {
  for (let i = 0; i < props.players.length; i++) {
    if (props.players[i]?.user.name === player.user.name)
      return "tile-" + (i + 1);
  }
  return "tile-" + player.user;
}

const templateAreaClass = computed(() => {
  return props.altView &&
    props.players.length % 2 === 0 &&
    props.players.length > 2
    ? "template-area-" + props.players.length + "-players-alt"
    : "template-area-" + props.players.length + "-players";
});

</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}
.player-view-wrapper {
  display: grid;

  // grid-template-rows: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;
  touch-action: manipulation;
}

.template-area-6-players {
  grid-template-columns: repeat(2, 1fr) auto repeat(1, 1fr);
  grid-template-areas:
    "tile-1 tile-3 menu tile-5"
    "tile-2 tile-4 menu tile-6";
  #tile-1,
  #tile-3,
  #tile-5 {
    transform: rotate(180deg);
  }
}

.template-area-6-players-alt {
  grid-template-columns: repeat(1, 1fr) repeat(1, 1fr) auto repeat(1, 1fr) repeat(
      1,
      1fr
    );
  grid-template-areas:
    "tile-1 tile-2 menu tile-4 tile-6"
    "tile-1 tile-3 menu tile-5 tile-6";
  #tile-2,
  #tile-4 {
    transform: rotate(180deg);
  }
  #tile-1 {
    writing-mode: sideways-rl;
  }

  #tile-6 {
    writing-mode: sideways-lr;
  }
}

.template-area-4-players-alt {
  grid-template-columns: repeat(1, 1fr) auto repeat(1, 1fr) repeat(1, 1fr);
  grid-template-areas:
    "tile-1 menu tile-2 tile-4"
    "tile-1 menu tile-3 tile-4";
  #tile-2,
  #tile-4 {
    transform: rotate(180deg);
  }
  #tile-1 {
    writing-mode: sideways-rl;
  }

  #tile-4 {
    writing-mode: sideways-lr;
  }
}

.template-area-5-players {
  grid-template-columns: repeat(2, 1fr) auto repeat(1, 1fr);
  grid-template-areas:
    "tile-1 tile-3 menu tile-5"
    "tile-2 tile-4 menu tile-5";
  #tile-1,
  #tile-3 {
    transform: rotate(180deg);
  }
  #tile-5 {
    // transform: rotate(270deg);
    writing-mode: sideways-lr;
  }
}

.template-area-4-players {
  grid-template-columns: repeat(1, 1fr) auto repeat(1, 1fr);
  grid-template-areas:
    "tile-1 menu tile-3"
    "tile-2 menu tile-4";

  #tile-1,
  #tile-3 {
    transform: rotate(180deg);
  }
}

.template-area-3-players {
  grid-template-columns: repeat(1, 1fr) auto repeat(1, 1fr);
  grid-template-areas:
    "tile-1 menu tile-3"
    "tile-2 menu tile-3";
  #tile-1 {
    transform: rotate(180deg);
  }
  #tile-3 {
    // transform: rotate(270deg);
    writing-mode: sideways-lr;
  }
}

.template-area-2-players {
  grid-template-columns: repeat(1, 1fr) auto;
  grid-template-areas:
    "tile-1 menu"
    "tile-2 menu";
  #tile-1 {
    transform: rotate(180deg);
  }
}

.player-field {
  background-color: #f4f4f4;
  padding: 10px;
}

.tile {
  display: flex;
  flex-direction: column;
}

#menu {
  display: flex;
  flex-direction: column;
  grid-area: menu;
  width: 50px;
  background-color: black;
}

/* Define grid areas for each tile */
#tile-1 {
  grid-area: tile-1;
}
#tile-2 {
  grid-area: tile-2;
}
#tile-3 {
  grid-area: tile-3;
}

#tile-4 {
  grid-area: tile-4;
}
#tile-5 {
  grid-area: tile-5;
}
#tile-6 {
  grid-area: tile-6;
}

/* Turn corners */
.tile:first-child {
  text-align: center;
}
</style>
