<template>
  <!-- 
  layouts: 
  1 : grid
  2 : grid
  3 : grid + header
  4 : grid || grid + header + footer
  5 : grid + header
  6 : grid || grid + header + footer
-->
  <!-- <SelectCommander></SelectCommander> -->
  <div class="game-view-wrapper">
    <!-- <button @click="loadUsers">load users</button> -->
    <PlayerOverview
      :alt-view="true"
      @on-game-action="onGameAction"
      :action-chain="actionChain"
      :players="players"
    ></PlayerOverview>
    <!-- <div class="menu">menu</div> -->
  </div>
</template>
<script lang="ts" setup>
import PlayerOverview from "@/components/PlayerOverview.vue";
import SelectCommander from "@/components/SelectCommander.vue";

import {
  Commander,
  GameAction,
  Player,
  User,
} from "@/components/lifelog.model";
import { ref } from "vue";

const leon: User = {
  name: "leon",
  avatar: "rando",
  defaultCommanders: [],
};

const fabi: User = {
  name: "fabi",
  avatar: "rando",
  defaultCommanders: [],
};
const tommy: User = {
  name: "tommy",
  avatar: "rando",
  defaultCommanders: [],
};
const olli: User = {
  name: "olli",
  avatar: "rando",
  defaultCommanders: [],
};

const niggo: User = {
  name: "niggo",
  avatar: "rando",
  defaultCommanders: [],
};

const calvin: User = {
  name: "calvin",
  avatar: "rando",
  defaultCommanders: [],
};

const commander: Commander = {
  name: "chatterfang",
  art_crop:
    "https://cards.scryfall.io/art_crop/front/1/7/1785cf85-1ac0-4246-9b89-1a8221a8e1b2.jpg?1626097239",
};

const leonPlayer: Player = {
  user: leon,
  commander: commander,
};

const fabiPlayer: Player = {
  user: fabi,
  commander: commander,
};
const tommyPlayer: Player = {
  user: tommy,
  commander: commander,
};
const olliPlayer: Player = {
  user: olli,
  commander: commander,
};

const niggoPlayer: Player = {
  user: niggo,
  commander: commander,
};

const calvinPlayer: Player = {
  user: calvin,
  commander: commander,
};

const players = [
  leonPlayer,
  fabiPlayer,
  tommyPlayer,
  olliPlayer,
  niggoPlayer,
  calvinPlayer
];

const actionChain = ref(new Array<GameAction>());

function onGameAction(action: GameAction) {
  actionChain.value.push(action);
}
</script>

<style>
.game-view-wrapper {
  width: 100%;
  height: 100%;
}
</style>
