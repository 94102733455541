enum ACTIONTYPE {
  LIFEGAIN,
  LIFELOSS,
  POISONGAIN,
  POISONLOSS,
  COMMANDERLIFEGAIN,
  COMMANDERLIFELOSS,
}

type Game = {
  started: Date;
  ended?: Date | null;
};

type GameAction = {
  type: ACTIONTYPE;
  value: number;
  source?: Player;
  target: Player;
};

type Commander = {
  scryfall_id?: string;
  name: string;
  art_crop: string;
};

type User = {
  name: string;
  avatar: string;
  defaultCommanders: Array<Commander>;
};

type Player = {
  user: User;
  commander: Commander;
};

function useActionChain(actionChain: Array<GameAction>, player: Player) {
  function getUserLifeFromActionChain() {
    let life = 40;
    actionChain
      .filter((action) => action.target.user.name == player.user.name)
      .forEach((action) => {
        switch (action.type) {
          case ACTIONTYPE.LIFEGAIN:
            life += action.value;
            break;
          case ACTIONTYPE.LIFELOSS:
            life -= action.value;
            break;
          case ACTIONTYPE.COMMANDERLIFEGAIN:
            life += action.value;
            break;
          case ACTIONTYPE.COMMANDERLIFELOSS:
            life -= action.value;
            break;
        }
      });
    return life;
  }

  return { getUserLifeFromActionChain };
}

export {
  Game,
  Player,
  User,
  Commander,
  GameAction,
  ACTIONTYPE,
  useActionChain,
};
