<template>
  <div
    class="player-tile-wrapper"
    :style="{ 'background-image': 'url(' + player.commander.art_crop + ')' }"
  >
    <div class="info-overlay right">
      <div class="player-infos">
        <h1>{{ player?.user.name }}</h1>
        <h2>{{ life }}</h2>
        <div class="commander-damage-btn">
          <button @click="openCommanderDamageOverlay">C</button>
        </div>
      </div>
    </div>
    <div class="controls">
      <div
        class="lifeloss-btn btn"
        @click="onGameAction(ACTIONTYPE.LIFELOSS, 1)"
      >
        -
      </div>
      <div
        class="lifegain-btn btn"
        @click="onGameAction(ACTIONTYPE.LIFEGAIN, 1)"
      >
        +
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineProps } from "vue";
import {
  Player,
  GameAction,
  ACTIONTYPE,
  useActionChain,
} from "./lifelog.model";

const props = defineProps<{
  player: Player;
  actionChain: Array<GameAction>;
}>();

const { getUserLifeFromActionChain } = useActionChain(
  props.actionChain,
  props.player,
);

const direction = computed(() => {});

const emit = defineEmits(["onGameAction"]);

const life = computed(() => {
  if (!props.actionChain || !props.player) return -999;
  return getUserLifeFromActionChain();
});

function onGameAction(type: number, value: number, source?: Player) {
  if (!props.player) return;

  const newAction: GameAction = {
    type: type,
    value: value,
    source: source,
    target: props.player,
  };

  emit("onGameAction", newAction);
}

function openCommanderDamageOverlay() {}
</script>

<style scoped>
.player-tile-wrapper {
  position: relative;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 55px;
}

.info-overlay {
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px; /* Need a specific value to work */
  border-radius: 0px 0px 15px 15px;
}
.controls {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.controls div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  align-content: center;
}

.btn:active {
  color: cyan;
}

.btn {
  font-size: 55px;
  border: none;
  touch-action: manipulation;
  color: red;
}
</style>
