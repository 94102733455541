<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
}
#app {
  font-family: Comfortaa, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  background-color: black;
  

}



</style>
